import {
  LOAD_USER_SUCCESS,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_FIELD,
} from './constants';

const initialState = {
  currentPassword: '',
  newPassword: '',
  repeatedPassword: '',
  user: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_USER_SUCCESS: {
      return {
        ...state,
        user: payload,
      };
    }
    case UPDATE_PASSWORD_SUCCESS: {
      return {
        ...initialState,
        user: state.user,
      };
    }
    case UPDATE_FIELD: {
      const { name, value } = payload;
      return {
        ...state,
        [name]: value,
      };
    }
    default:
      return state;
  }
};
