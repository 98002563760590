import React from 'react';
import { Resource } from 'react-admin';
import PersonAddDisabled from '@material-ui/icons/PersonAddDisabled';

import List from './List';

export default (
  <Resource
    name="deactivated-accounts"
    icon={PersonAddDisabled}
    list={List}
  />
);
