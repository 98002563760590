import Menu from '@material-ui/icons/Menu';
import composeResource from '../../../shared';

const fields = [
  {
    name: 'name',
    label: 'Name',
    type: 'translatable-string',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'translatable-text',
  },
  {
    name: 'estimatedTime',
    label: 'Estimated time, sec',
    type: 'number',
  },
];

const path = 'procedures';

export default composeResource({ fields, path, icon: Menu });
