import React from 'react';
import { node } from 'prop-types';
import {
  TextInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
} from 'react-admin';
import { createRenderOptionText } from '../../shared/render-option-text';

const optionRenderer = createRenderOptionText('content');
const DepartmentsForm = ({ toolbar }) => (
  <SimpleForm toolbar={toolbar}>
    <TextInput multiline source="name" />

    <ReferenceInput
      source="instituteId"
      reference="institutes"
      label="Institute"
      perPage={100}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceArrayInput
      source="timeoutIds"
      reference="timeouts"
      label="Time-out questions"
    >
      <SelectArrayInput
        style={{ width: 'calc(100% - 48px)' }}
        optionText={optionRenderer}
      />
    </ReferenceArrayInput>
  </SimpleForm>
);

DepartmentsForm.propTypes = {
  toolbar: node.isRequired,
};

export default DepartmentsForm;
