import React from 'react';
import { bool, node } from 'prop-types';
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  CheckboxGroupInput,
  FormDataConsumer,
} from 'react-admin';

import { ROLES, LANGUAGES } from '../../constants';

const formatToInput = (values) => {
  if (!values) {
    return [];
  }
  return Object.keys(values).filter((key) => values[key]);
};

const parseToRecord = (values) =>
  ROLES.map((item) => item.id).reduce((res, cur) => {
    res[cur] = values.includes(cur);
    return res;
  }, {});

const UserFormView = ({ isCreateForm, toolbar }) => (
  <SimpleForm toolbar={toolbar}>
    <TextInput source="email" />
    <TextInput source="givenName" />
    <TextInput source="surname" />

    <TextInput source="title" />
    <TextInput source="supervisor" />
    <TextInput multiline source="imageUrl" />
    <TextInput source="phone" />

    {isCreateForm && <TextInput source="password" />}

    <ReferenceInput
      source="institute.id"
      reference="institutes"
      label="Institute"
      perPage={10000}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <FormDataConsumer>
      {({ formData, ...rest }) => {
        if (formData.institute && formData.institute.id) {
          return (
            <ReferenceInput
              source="department.id"
              reference="departments"
              filter={{ instituteId: formData.institute.id }}
              label="Department"
              {...rest}
              allowEmpty
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          );
        }

        return null;
      }}
    </FormDataConsumer>

    <SelectInput source="settings.lang" label="Language" choices={LANGUAGES} />

    <CheckboxGroupInput
      label="Roles"
      source="roles"
      format={formatToInput}
      parse={parseToRecord}
      choices={ROLES}
    />
  </SimpleForm>
);

UserFormView.propTypes = {
  isCreateForm: bool,
  toolbar: node.isRequired,
};

UserFormView.defaultProps = {
  isCreateForm: false,
};

export default UserFormView;
