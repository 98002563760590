import TimeOut from '@material-ui/icons/AccessAlarm';
import composeResource from '../../../shared';

const fields = [
  {
    name: 'content',
    label: 'Content',
    type: 'translatable-text',
  },
  {
    name: 'order',
    label: 'Order',
    type: 'number',
    positive: true,
  },
];

const path = 'timeouts';

export default composeResource({ fields, path, icon: TimeOut });
