import { AUTH_ERROR } from 'react-admin';

import {
  LOAD_USER_START,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_FIELD,
} from './constants';

export const loadUser = () => ({
  type: LOAD_USER_START,
});

export const loadUserSuccess = (payload) => ({
  type: LOAD_USER_SUCCESS,
  payload,
});

export const loadUserFail = () => ({
  type: LOAD_USER_FAIL,
});

export const updatePassword = (payload) => ({
  type: UPDATE_PASSWORD_START,
  payload,
});

export const updatePasswordSuccess = () => ({
  type: UPDATE_PASSWORD_SUCCESS,
});

export const updatePasswordFail = () => ({
  type: UPDATE_PASSWORD_FAIL,
});

export const updateField = (payload) => ({
  type: UPDATE_FIELD,
  payload,
});

export const redirectToLogin = () => ({
  type: AUTH_ERROR,
  meta: { redirectTo: '/login' },
});
