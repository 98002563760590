import React from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-final-form';

import { SaveButton, Toolbar, DeleteButton } from 'react-admin';

const SaveButtonView = (props) => {
  const {
    basePath,
    handleSubmit,
    redirect,
    save,
    handleSubmitWithRedirect,
    ...restProps
  } = props;
  const form = useForm();
  const handleClick = () => {
    const { values } = form.getState();
    save(values, basePath, redirect);
  };

  return <SaveButton handleSubmitWithRedirect={handleClick} {...restProps} />;
};

const style = {
  display: 'flex',
  justifyContent: 'space-between',
};

export default (action, allowDelete) => {
  const SaveItemButton = connect(undefined, { save: action })(SaveButtonView);

  return (props) => (
    <Toolbar {...props} style={style}>
      <SaveItemButton label="Save" redirect="list" submitOnEnter />

      {allowDelete && <DeleteButton {...props} />}
    </Toolbar>
  );
};
