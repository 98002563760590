import { crudUpdate, crudCreate } from 'react-admin';

import omit from 'lodash/omit';
import { GENERATED_FIELDS } from '../../constants';

const getUpdatedValues = (values) => {
  return omit(values, GENERATED_FIELDS);
};

export const runUpdate = (values, basePath, redirectTo) => {
  const { id } = values;

  const updatedValues = getUpdatedValues(values);

  return crudUpdate(
    'glossaries',
    id,
    updatedValues,
    updatedValues,
    basePath,
    redirectTo,
  );
};

export const runCreate = (values, basePath, redirectTo) => {
  const updatedValues = getUpdatedValues(values);

  return crudCreate('glossaries', updatedValues, basePath, redirectTo);
};
