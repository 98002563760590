import axios from 'axios';
import get from 'lodash/get';

const apiUrl = process.env.REACT_APP_API_URL;
const refreshUrl = `${apiUrl}/auth/refresh`;
const authUrl = `${apiUrl}/auth/admin/login`;

const getPreparedValidationError = (error) => {
  try {
    let message;
    const dataMessage = error?.response?.data?.message ?? null;

    if (!dataMessage) {
      return error;
    }

    if (typeof dataMessage === 'object') {
      const messages = Object.values(dataMessage);
      if (messages.length) {
        message = `Validation Error 400: ${messages.join('; ')}`;
      }
    } else if (typeof dataMessage === 'string') {
      message = dataMessage;
    }
    return { ...error, message };
  } catch (tryError) {
    return error;
  }
};

export class Api {
  constructor(options = {}) {
    this.user = null;
    this.client = options.client || axios.create();
    this.accessToken = localStorage.getItem('accessToken');
    this.refreshToken = localStorage.getItem('refreshToken');
    this.refreshRequest = null;

    this.client.interceptors.request.use(
      (config) => {
        if (!this.accessToken) {
          return config;
        }

        const newConfig = { ...config };

        newConfig.headers.Authorization = `Bearer ${this.accessToken}`;

        return newConfig;
      },
      (e) => Promise.reject(e),
    );

    this.client.interceptors.response.use(
      (r) => r,
      async (error) => {
        // Validation error
        if (error.response.status === 400) {
          throw getPreparedValidationError(error);
        }

        if (error.response.status !== 401 || error.config.retry) {
          throw error;
        }

        if (!this.refreshToken) {
          const shortError = {
            ...get(error, 'response.data'),
            isRedirectLogin: true,
          };

          throw shortError;
        }

        if (!this.refreshRequest) {
          const body = { refreshToken: this.refreshToken };

          this.refreshRequest = this.client.post(refreshUrl, body);
        }

        const { data } = await this.refreshRequest;
        this.refreshRequest = null;
        const { accessToken, refreshToken } = data;
        this.setTokens(accessToken, refreshToken);

        const newRequest = {
          ...error.config,
          retry: true,
        };

        return this.client(newRequest);
      },
    );
  }

  setTokens(accessToken, refreshToken) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  }

  async login({ email, password }) {
    const res = await this.client
      .post(authUrl, {
        email,
        password,
      })
      .catch((err) => {
        const error = err.response?.data?.message;
        throw error;
      });

    const { accessToken, refreshToken, ...user } = res.data;
    this.user = user;

    this.setTokens(accessToken, refreshToken);
  }

  async checkUser() {
    if (this.user) {
      return this.user;
    }

    try {
      const {
        data: { user },
      } = await this.get('/user/me');
      this.user = user;
      return user;
    } catch (error) {
      return null;
    }
  }

  async checkLogin() {
    const user = await this.checkUser();
    if (user && !user.roles.admin) {
      await this.logout();
      return false;
    }

    return Boolean(
      (user && this.accessToken && this.refreshToken) ||
        (localStorage.getItem('accessToken') &&
          localStorage.getItem('refreshToken')),
    );
  }

  async logout() {
    this.accessToken = null;
    this.refreshToken = null;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    this.user = null;
  }

  async getPermissions() {
    const user = await this.checkUser();
    return user?.roles ? { ...user.roles } : { guest: true };
  }

  async get(url) {
    const res = await this.client(`${apiUrl}/${url}`);

    const { data, headers } = res;
    const rangeHeader = headers['content-range'];

    const total = rangeHeader
      ? parseInt(rangeHeader.split('/')[1], 10)
      : data.length;

    return { data, total };
  }

  async post(url, body) {
    const { data } = await this.client.post(`${apiUrl}/${url}`, body);
    return { data };
  }

  async put(url, body) {
    const { data } = await this.client.put(`${apiUrl}/${url}`, body);
    return { data };
  }

  async patch(url, body) {
    const { data } = await this.client.patch(`${apiUrl}/${url}`, body);
    return { data };
  }

  async delete(url) {
    const res = await this.client.delete(`${apiUrl}/${url}`);
    const { data } = res;
    return { data };
  }

  async deleteMany(url, ids) {
    const requests = ids.map((id) =>
      this.client.delete(`${apiUrl}/${url}/${id}`),
    );
    const data = await Promise.all(requests);
    return { data };
  }

  async fetchCustomCSV(data) {
    const formData = new FormData();
    formData.append('file', data);
    return this.post(`/glossaries/import`, formData);
  }

  async sendGlossariesToDeepL() {
    return this.post('/glossaries/send-to-deepl');
  }
}

const api = new Api();

export default api;
