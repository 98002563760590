import React from 'react';
import { node } from 'prop-types';
import {
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import AutocompleteInput from './components/AutocompleteInput';
import { LANGUAGES } from '../constants';
import { positiveNumber } from './validations';
import { renderOptionText } from './render-option-text';

const getComponent = (field) => {
  const { type, name, label, isReference, perPage } = field;

  if (isReference) {
    return (
      <ReferenceInput
        key={name}
        source={field.source}
        reference={field.resource}
        label={field.label}
        allowEmpty
        perPage={perPage}
      >
        <AutocompleteInput allowEmpty optionText={renderOptionText} />
      </ReferenceInput>
    );
  }

  switch (type) {
    case 'translatable-string':
    case 'translatable-text':
      return LANGUAGES.map((lang) => (
        <TextInput
          multiline
          key={`${name}-${lang.id}`}
          source={`${name}.${lang.id}`}
          label={`${name} (${lang.shortName})`}
        />
      ));
    case 'number':
      return (
        <NumberInput
          key={name}
          source={name}
          label={label}
          validate={field.positive ? [positiveNumber()] : []}
        />
      );
    default:
      return <TextInput key={name} source={name} label={label} />;
  }
};

const getForm = (fields) => {
  const PatientRiskFormView = ({ toolbar }) => (
    <SimpleForm toolbar={toolbar}>
      <TextInput readonly source="id" />

      {fields.map((field) => getComponent(field))}
    </SimpleForm>
  );

  PatientRiskFormView.propTypes = {
    toolbar: node.isRequired,
  };

  return PatientRiskFormView;
};

export default getForm;
