import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  Filter,
  TextInput,
} from 'react-admin';

import TranslatedField from './components/TranslatedField';
import ReferenceField from './components/ReferenceField';

const getComponent = (type) => {
  switch (type) {
    case 'translatable-string':
      return TranslatedField;
    case 'translatable-text':
      return TranslatedField;
    case 'integer':
      return NumberField;
    default:
      return TextField;
  }
};

const FilterComponent = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
  </Filter>
);

const getListComponent = (fields) => (props) => (
  <List {...props} filters={<FilterComponent {...props} />}>
    <Datagrid rowClick="edit" style={{ alignItems: 'top' }}>
      <TextField source="id" />
      {fields.map((field) => {
        const Component = getComponent(field.type);

        if (!field.isReference) {
          return (
            <Component
              key={field.name}
              source={field.name}
              label={field.label}
            />
          );
        }

        return (
          <ReferenceField key={field.name} field={field}>
            <Component source={field.name} />
          </ReferenceField>
        );
      })}
    </Datagrid>
  </List>
);

export default getListComponent;
