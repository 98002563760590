import React from 'react';
import { useNotify, useDelete, useRefresh } from 'react-admin';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyle = makeStyles(theme => ({
  activateButton: {
    color: '#229645',
    '&:hover': {
      backgroundColor: fade('#229645', 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

export const ActivateAccountButton = ({ record, basePath, resource }) => {
  const classes = useStyle();
  const notify = useNotify();
  const refresh = useRefresh();
  const [create, { loading }] = useDelete(
    'deactivated-accounts',
    record.id,
    record,
    {
      onSuccess: () => {
        notify(`The user #${record.id} was activated.`, 'info');
        refresh();
      },
      onFailure: () => {
        notify(`Failed to activate the user #${record.id}.`, 'error')
      },
    });

  const handleCreate = (e) => {
    e.stopPropagation();
    e.preventDefault();
    create();
  }
  return <Button
    className={classes.activateButton}
    disabled={loading}
    onClick={handleCreate}
    variant={'text'}
  >Activate</Button>;
};
