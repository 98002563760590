import composeResource from '../../../shared';

const fields = [
  {
    name: 'name',
    label: 'Name',
    type: 'translatable-string',
  },
  {
    name: 'name',
    label: 'Parent category',
    type: 'translatable-string',
    isReference: true,
    resource: 'categories',
    source: 'parentId',
    perPage: false,
  },
];

const path = 'categories';

export default composeResource({ fields, path });
