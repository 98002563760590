export const LANGUAGES = [
  {
    name: 'English',
    shortName: 'En',
    id: 'en',
  },
  {
    name: 'German',
    shortName: 'De',
    id: 'de',
  },
];

export const ROLES = [
  {
    id: 'covid-19',
    name: 'COVID-19',
  },
  {
    id: 'surgeon',
    name: 'Surgeon',
  },
  {
    id: 'admin',
    name: 'Admin',
  },
  {
    id: 'beta-tester',
    name: 'Beta Tester',
  },
];

export const GENERATED_FIELDS = [
  'id',
  'createdBy',
  'updatedBy',
  'createdAt',
  'updatedAt',
  'isDeleted',
];

export const SPECIAL_FIELDS = ['count'];
