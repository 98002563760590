import React from 'react';
import { Create } from 'react-admin';

import getToolbar from '../../shared/toolbar';
import { runCreate } from './actions';
import Form from './Form';

const Toolbar = getToolbar(runCreate, false);

const CreateForm = (props) => (
  <Create title="Create a glossary" {...props}>
    {Form({ toolbar: <Toolbar /> })}
  </Create>
);

export default CreateForm;
