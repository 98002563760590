import Equipment from '@material-ui/icons/Colorize';
import composeResource from '../../../shared';

const fields = [
  {
    name: 'name',
    label: 'Name',
    type: 'translatable-string',
  },
  {
    name: 'company',
    label: 'Company',
  },
  {
    name: 'size',
    label: 'Size',
  },
  {
    name: 'serialNumber',
    label: 'Serial number',
  },
];

const path = 'equipment';

export default composeResource({ fields, path, icon: Equipment });
