import React, { Component } from 'react';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styled from 'styled-components';

import { loadUser, updatePassword, updateField } from './actions';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  div + button,
  div + div {
    margin-top: 16px;
  }
`;

class UserSettings extends Component {
  static propTypes = {
    loadUser: func.isRequired,
    updatePassword: func.isRequired,
    updateField: func.isRequired,
    user: shape({}),
    newPassword: string,
    currentPassword: string,
    repeatedPassword: string,
  };

  static defaultProps = {
    user: null,
    newPassword: '',
    currentPassword: '',
    repeatedPassword: '',
  };

  componentDidMount() {
    this.props.loadUser();
  }

  submit = (e) => {
    e.preventDefault();
    const { newPassword, currentPassword, repeatedPassword } = this.props;
    this.props.updatePassword({
      currentPassword,
      newPassword,
      repeatedPassword,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.props.updateField({ name, value });
  };

  render() {
    const { currentPassword, newPassword, repeatedPassword, user } = this.props;

    if (!user) {
      return null;
    }

    return (
      <Card style={{ maxWidth: '300px' }}>
        <CardContent>
          <Form onSubmit={this.submit}>
            <TextField label="email" value={user.email} disabled />
            <TextField
              label="Current password"
              onChange={this.handleChange}
              value={currentPassword}
              name="currentPassword"
              type="password"
            />
            <TextField
              label="New password"
              onChange={this.handleChange}
              value={newPassword}
              name="newPassword"
              type="password"
            />
            <TextField
              label="Repeat new password"
              onChange={this.handleChange}
              value={repeatedPassword}
              name="repeatedPassword"
              type="password"
            />

            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Form>
        </CardContent>
      </Card>
    );
  }
}

const mapDispatchToProps = { loadUser, updatePassword, updateField };
const Connected = connect(
  (state) => state.user,
  mapDispatchToProps,
)(UserSettings);

export default Connected;
