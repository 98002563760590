import React from 'react';
import { Resource } from 'react-admin';
import Domain from '@material-ui/icons/Domain';

import List from './List';
import Create from './Create';
import Edit from './Edit';

export default (
  <Resource
    name="departments"
    list={List}
    edit={Edit}
    create={Create}
    icon={Domain}
  />
);
