import { put, takeEvery, call } from 'redux-saga/effects';
import get from 'lodash/get';
import { showNotification, FETCH_ERROR } from 'react-admin';

import api from '../../api';
import {
  loadUserSuccess,
  loadUserFail,
  updatePasswordSuccess,
  updatePasswordFail,
  redirectToLogin,
} from './actions';

import { LOAD_USER_START, UPDATE_PASSWORD_START } from './constants';

export function* loadUser() {
  try {
    const response = yield call([api, 'get'], 'user/me');

    yield put(loadUserSuccess(response.data.user));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(redirectToLogin());
    }
    yield put(showNotification(`Error: ${e.message}`, 'warning'));
    yield put(loadUserFail(e.message));
  }
}

export function* updatePassword({ payload }) {
  try {
    const { newPassword, currentPassword, repeatedPassword } = payload;

    if (newPassword !== repeatedPassword) {
      throw new Error('Repeated password is incorrect.');
    }

    yield call([api, 'post'], 'auth/update_password', {
      newPassword,
      currentPassword,
    });

    yield put(updatePasswordSuccess());
    yield put(showNotification('Password was successfully updated'));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(redirectToLogin());
    }
    yield put(showNotification(`Error: ${e.message}`, 'warning'));
    yield put(updatePasswordFail(e.message));
  }
}

export function* handleFetchError({ error = {} }) {
  try {
    if (get(error, 'isRedirectLogin')) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      yield put(redirectToLogin());
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Unexpected FETCH module error', { ...e });
  }
}

export default function* userSaga() {
  yield takeEvery(LOAD_USER_START, loadUser);
  yield takeEvery(UPDATE_PASSWORD_START, updatePassword);
  yield takeEvery(FETCH_ERROR, handleFetchError);
}
