import Risks from '@material-ui/icons/PriorityHigh';
import composeResource from '../../../shared';

const fields = [
  {
    name: 'name',
    label: 'Name',
    type: 'translatable-string',
  },

  {
    name: 'mitigationAction',
    label: 'Mitigation Action',
    type: 'translatable-text',
  },
];

const path = 'risks';

export default composeResource({ fields, path, icon: Risks });
