import { NOT_FOUND } from 'http-status-codes';
import api from '../api';

export const authProvider = {
  login: ({ email, password }: { email: string; password: string }) =>
    api.login({ email, password }),
  logout: () => api.logout(),
  checkAuth: () => api.checkLogin(),
  checkError: (error) => {
    if (
      error?.config?.url.includes('/auth/refresh') &&
      error?.response?.status === NOT_FOUND
    ) {
      return Promise.reject('Please login');
    }

    return Promise.resolve();
  },
  getPermissions: () => {
    return api.getPermissions();
  },
};
