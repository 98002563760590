import React from 'react';
import { List, Datagrid, TextField, EmailField, DateField, Filter, SearchInput } from 'react-admin';
import { DeactivatedAccountsManageButton } from '../DeactivatedAccounts/buttons/DeactivatedAccountsManageButton';

const Search = props => <Filter {...props}>
  <SearchInput source="q" alwaysOn />
</Filter>

export const Users = (props) => (
  <List {...props} filters={<Search/>}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="givenName" />
      <TextField source="surname" />
      <EmailField source="email" sortable={false} />
      <TextField source="phone" sortable={false} />

      <TextField source="institute.name" label="Institute" sortable={false} />
      <TextField source="department.name" label="Department" sortable={false} />
      <TextField source="settings.lang" label="Lang" sortable={false} />
      <DateField
        showTime
        source="lastLoginAt"
        label="Last login date"

      />
      <DeactivatedAccountsManageButton />
    </Datagrid>
  </List>
);

export default Users;
