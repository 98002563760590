import React, { Fragment } from 'react';
import { Admin } from 'react-admin';
import { Route } from 'react-router-dom';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import patientRisksResource from './screens/Libraries/PatientRisks';
import instrumentsResource from './screens/Libraries/Instruments';
import equipmentResource from './screens/Libraries/Equipment';
import implantsResource from './screens/Libraries/Implants';
import timeoutsResource from './screens/Libraries/Timeouts';
import proceduresResource from './screens/Libraries/Procedures';
import risksResource from './screens/Libraries/Risks';
import checksResource from './screens/Libraries/Checks';
import debriefingsResource from './screens/Libraries/Debriefings';
import categoriesResource from './screens/Libraries/Categories';
import operationTemplatesResource from './screens/OperationTemplates';

import usersResource from './screens/Users';
import deactivatedAccountsResource from './screens/DeactivatedAccounts';
import departmentsResource from './screens/Departments';
import institutesResource from './screens/Institutes';
import glossariesResource from './screens/Glossaries';

import { LoginPage } from './screens/Login';
import { user } from './custom';

import { Layout } from './layout';
import { dataProvider } from './providers/data-provider';
import { authProvider } from './providers/auth-provider'; // eslint-disable-line

const customRoutes = [
  <Route exact path="/settings" component={user.Component} />,
];

const customSagas = [user.sagas];
const customReducers = { user: user.reducer };

if (process.env.REACT_APP_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    appVersion: process.env.REACT_APP_VERSION,
    releaseStage: process.env.REACT_APP_APP_ENV || 'development',
    enabledReleaseStages: ['staging', 'production'],
    plugins: [new BugsnagPluginReact()],
  });
}

const ErrorBoundary = process.env.REACT_APP_BUGSNAG_API_KEY
  ? Bugsnag.getPlugin('react').createErrorBoundary(React)
  : Fragment;

const App = () => (
  <ErrorBoundary>
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={LoginPage}
      layout={Layout}
      customRoutes={customRoutes}
      customSagas={customSagas}
      customReducers={customReducers}
    >
      {(permissions) => {
        if (!permissions.admin) return null;

        return [
          patientRisksResource,
          instrumentsResource,
          equipmentResource,
          implantsResource,
          timeoutsResource,
          proceduresResource,
          risksResource,
          categoriesResource,
          checksResource,
          debriefingsResource,
          usersResource,
          deactivatedAccountsResource,
          departmentsResource,
          institutesResource,
          operationTemplatesResource,
          glossariesResource,
        ];
      }}
    </Admin>
  </ErrorBoundary>
);

export default App;
