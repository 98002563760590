import React from 'react';
import { Edit } from 'react-admin';

import getToolbar from '../../shared/toolbar';
import { runUpdate } from './actions';
import Form from './Form';

const Toolbar = getToolbar(runUpdate, true);

const EditForm = (props) => (
  <Edit title="Edit institute" {...props}>
    {Form({ toolbar: <Toolbar /> })}
  </Edit>
);

export default EditForm;
