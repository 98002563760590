import { crudUpdate, crudCreate } from 'react-admin';

import { getUpdatedValues } from './utils';

const getUpdateAction = (path) => (values, basePath, redirectTo) => {
  const { id } = values;
  const updatedValues = getUpdatedValues(values);
  if (path === 'categories' && !values.parentId) {
    updatedValues.parentId = null;
  }

  return crudUpdate(
    path,
    id,
    updatedValues,
    updatedValues,
    basePath,
    redirectTo,
  );
};

const getCreateAction = (path) => (values, basePath, redirectTo) => {
  const updatedValues = getUpdatedValues(values);

  return crudCreate(path, updatedValues, basePath, redirectTo);
};

const getActions = (path) => ({
  runUpdate: getUpdateAction(path),
  runCreate: getCreateAction(path),
});

export default getActions;
