import React from 'react';
import { Create } from 'react-admin';

import getToolbar from './toolbar';
import getForm from './form';

const getCreateForm = (fields, actions) => {
  const Toolbar = getToolbar(actions.runCreate, false);
  const Form = getForm(fields);

  return (props) => (
    <Create title="Create" {...props}>
      {Form({ toolbar: <Toolbar /> })}
    </Create>
  );
};

export default getCreateForm;
