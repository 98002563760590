import { crudUpdate, crudCreate } from 'react-admin';
import cleanDeep from 'clean-deep';
import pick from 'lodash/pick';

const USER_FIELDS = [
  'email',
  'givenName',
  'imageUrl',
  'password',
  'phone',
  'roles',
  'settings',
  'supervisor',
  'surname',
  'title',
];

const getUpdatedValues = (values) => {
  const { institute, department } = values;

  const instituteId = institute ? institute.id : null;
  const departmentId = department ? department.id : null;

  return cleanDeep({
    ...pick(values, USER_FIELDS),
    instituteId,
    departmentId,
  });
};

export const runUpdate = (values, basePath, redirectTo) => {
  const { id: userId } = values;

  const updatedValues = getUpdatedValues(values);

  return crudUpdate(
    'users',
    userId,
    updatedValues,
    updatedValues,
    basePath,
    redirectTo,
  );
};

export const runCreate = (values, basePath, redirectTo) => {
  const updatedValues = getUpdatedValues(values);

  return crudCreate('users', updatedValues, basePath, redirectTo);
};
