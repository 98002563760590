import React from 'react';
import { node } from 'prop-types';
import { SimpleForm, TextInput } from 'react-admin';

const GlossaryForm = ({ toolbar }) => (
  <SimpleForm toolbar={toolbar} sanitizeEmptyValues={false}>
    <TextInput source="en" parse={(value) => value} />
    <TextInput source="de" parse={(value) => value} />
    <TextInput source="es" parse={(value) => value} emptyValue={null} />
    <TextInput source="fr" parse={(value) => value} emptyValue={null} />
    <TextInput source="it" parse={(value) => value} emptyValue={null} />
    <TextInput source="ja" parse={(value) => value} emptyValue={null} />
    <TextInput source="pl" parse={(value) => value} emptyValue={null} />
    <TextInput source="nl" parse={(value) => value} emptyValue={null} />
    <TextInput source="category" parse={(value) => value} emptyValue={null} />
  </SimpleForm>
);

GlossaryForm.propTypes = {
  toolbar: node.isRequired,
};

export default GlossaryForm;
