import React from 'react';
import { Resource } from 'react-admin';
import City from '@material-ui/icons/LocationCity';

import List from './List';
import Create from './Create';
import Edit from './Edit';

export default (
  <Resource
    name="institutes"
    list={List}
    edit={Edit}
    create={Create}
    icon={City}
  />
);
