import React from 'react';
import { node, arrayOf, oneOfType, shape } from 'prop-types';
import { ReferenceField } from 'react-admin';

const ExtendedReferenceField = ({ children, field, record, ...rest }) => {
  if (!record || !record[field.source]) return null;

  return (
    <ReferenceField
      label={field.label}
      source={field.source}
      reference={field.resource}
      record={record}
      {...rest}
    >
      {children}
    </ReferenceField>
  );
};

ExtendedReferenceField.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  field: shape({}).isRequired,
  record: shape({}),
};

ExtendedReferenceField.defaultProps = {
  record: null,
};

export default ExtendedReferenceField;
