import React from 'react';
import { Resource } from 'react-admin';
import UsersIcon from '@material-ui/icons/SupervisorAccount';

import List from './List';
import Edit from './EditUser';
import Create from './CreateUser';

export default (
  <Resource
    name="users"
    icon={UsersIcon}
    list={List}
    edit={Edit}
    create={Create}
  />
);
