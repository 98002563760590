import React from 'react';
import { List, Datagrid, TextField, EmailField, DateField } from 'react-admin';
import { ActivateAccountButton } from './buttons/ActivateAccountButton'

export const DeactivatedAccounts = (props) => (
  <List {...props} bulkActionButtons={false} >
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="givenName" />
      <TextField source="surname" />
      <EmailField source="email" />
      <TextField source="phone" />

      <TextField source="institute.name" label="Institute" />
      <TextField source="department.name" label="Department" />
      <DateField
        showTime
        source="lastLoginDate"
        label="Last login date"
      />
      <DateField
        showTime
        source="deactivatedInfo.createdAt"
        label="Deactivated date"
      />

      <ActivateAccountButton />
    </Datagrid>
  </List>
);

export default DeactivatedAccounts;
