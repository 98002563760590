import React from 'react';
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';

export const Departments = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />

      <ReferenceField
        label="Institute"
        source="instituteId"
        reference="institutes"
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default Departments;
