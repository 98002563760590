import React from 'react';
import { Resource } from 'react-admin';

import getActions from './actions';
import getListComponent from './list';
import getEditComponent from './edit';
import getCreateComponent from './create';

function composeResource({ fields, path, icon, editTransform }) {
  const actions = getActions(path);

  const List = getListComponent(fields);
  const Edit = getEditComponent(fields, actions, editTransform);
  const Create = getCreateComponent(fields, actions);

  return (
    <Resource name={path} list={List} edit={Edit} create={Create} icon={icon} />
  );
}

export default composeResource;
