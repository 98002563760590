import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

import {
  userLogin as login,
  useNotify,
  useLogin,
  Notification,
} from 'react-admin';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  div + button,
  div + div {
    margin-top: 16px;
  }
`;

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [authError, setAuthError] = useState(false);

  const notify = useNotify();
  const login = useLogin();

  const onSubmit = (e) => {
    e.preventDefault();

    if (email === '') {
      setEmailError('Email is required');
      return;
    }

    if (password === '') {
      setPasswordError('Password is required');
      return;
    }

    login({ email, password }).catch((errMessage) => {
      const { email, password } = errMessage;
      if (!email && !password) {
        notify(errMessage, 'error');
        setAuthError(true);
        return;
      }

      setEmailError(email);
      setPasswordError(password);
    });
  };

  const handleChangeEmail = (e) => {
    setEmailError(null);
    setAuthError(false);
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPasswordError(null);
    setAuthError(false);
    setPassword(e.target.value);
  };

  return (
    <Wrapper>
      <Form onSubmit={onSubmit}>
        <TextField
          error={!!emailError || authError}
          helperText={emailError}
          label="Email"
          onChange={handleChangeEmail}
          value={email}
        />
        <TextField
          error={!!passwordError || authError}
          helperText={passwordError}
          label="Password"
          onChange={handleChangePassword}
          value={password}
          type="password"
        />

        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!!passwordError || !!emailError}
        >
          Login
        </Button>
      </Form>
      <Notification />
    </Wrapper>
  );
};

export default LoginPage;
