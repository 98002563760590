import composeResource from '../../shared';

const fields = [
  {
    name: 'name',
    label: 'Name',
    type: 'translatable-string',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'translatable-text',
  },
  {
    name: 'notes',
    label: 'Post-OP notes',
    type: 'translatable-text',
  },
];

const path = 'operation_templates';

const OperationTemplatesResource = composeResource({
  fields,
  path,
  icon: null,
  editTransform: (data) => {
    const { name, description } = data;
    return { name, description };
  },
});

export default OperationTemplatesResource;
