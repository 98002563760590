import React from 'react';
import { Resource } from 'react-admin';
import BookRounded from '@material-ui/icons/BookRounded';

import List from './List';
import Create from './Create';
import Edit from './Edit';

export default (
  <Resource
    name="glossaries"
    list={List}
    edit={Edit}
    create={Create}
    icon={BookRounded}
  />
);
