import React from 'react';
import { Edit } from 'react-admin';

import getToolbar from './toolbar';
import getForm from './form';

const getEditForm = (fields, actions) => {
  const Toolbar = getToolbar(actions.runUpdate, true);
  const Form = getForm(fields);

  return (props) => (
    <Edit title="Edit" {...props}>
      {Form({ toolbar: <Toolbar /> })}
    </Edit>
  );
};

export default getEditForm;
