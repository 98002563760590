import { get } from 'lodash';

export const renderOptionText = (choice, target = 'name') => {
  if (!choice || !target) return null;
  if (!choice.id) return 'None';

  return `EN: ${get(choice, `${target}.en`, 'N/A')} DE: ${get(
    choice,
    `${target}.de`,
    'N/A',
  )}`;
};

export const createRenderOptionText = (target) => (choice) =>
  renderOptionText(choice, target);
