import { crudUpdate, crudCreate } from 'react-admin';

import omit from 'lodash/omit';
import { GENERATED_FIELDS } from '../../constants';

const getUpdatedValues = (values) => {
  const { address, ...rest } = values;

  rest.address = omit(address, GENERATED_FIELDS);

  return omit(rest, GENERATED_FIELDS);
};

export const runUpdate = (values, basePath, redirectTo) => {
  const { id } = values;

  const updatedValues = getUpdatedValues(values);

  return crudUpdate(
    'institutes',
    id,
    updatedValues,
    updatedValues,
    basePath,
    redirectTo,
  );
};

export const runCreate = (values, basePath, redirectTo) => {
  const updatedValues = getUpdatedValues(values);

  return crudCreate('institutes', updatedValues, basePath, redirectTo);
};
