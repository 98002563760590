import PatientRisks from '@material-ui/icons/PersonPin';
import composeResource from '../../../shared';

const fields = [
  {
    name: 'name',
    label: 'Name',
    type: 'translatable-string',
  },
  {
    name: 'mitigationAction',
    label: 'Mitigation Action',
    type: 'translatable-text',
  },
];

const path = 'patient_risks';

export default composeResource({ fields, path, icon: PatientRisks });
