import React from 'react';
import { string, shape } from 'prop-types';
import styled from 'styled-components';

import { LANGUAGES } from '../../constants';

const Wrapper = styled.div`
  div + div {
    margin-top: 8px;
  }
  min-width: 200px;
`;

const Translated = ({ source, record }) => {
  if (!record) return null;

  return (
    <Wrapper>
      {LANGUAGES.map((lang) => {
        const value = record[source][lang.id] || null;

        if (!value) return null;

        return <div key={lang.id}>{`${lang.shortName}: ${value}`}</div>;
      })}
    </Wrapper>
  );
};

Translated.propTypes = {
  record: shape({}),
  source: string.isRequired,
};

Translated.defaultProps = {
  record: null,
};

export default Translated;
