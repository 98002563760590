import React from 'react';
import { Create } from 'react-admin';

import getToolbar from '../../shared/toolbar';
import { runCreate } from './actions';
import Form from './Form';

const Toolbar = getToolbar(runCreate, false);

const CreateUserView = (props) => (
  <Create title="Create user" {...props}>
    {Form({ toolbar: <Toolbar />, isCreateForm: true })}
  </Create>
);

export default CreateUserView;
