import { stringify } from 'query-string';
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  GET_MANY,
  DELETE_MANY,
} from 'react-admin';
import { isEmpty } from 'lodash';

import api from '../api';

export const dataProvider = (type, resource, params) => {
  let url = '';
  let method = 'get';

  let { data } = params;

  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {};

      if (field && order) {
        query.sort = JSON.stringify([field, order]);
      }

      if (!isEmpty(params.filter)) {
        query.filter = JSON.stringify(params.filter);
      }

      if (perPage) {
        query.range = JSON.stringify([
          (page - 1) * perPage,
          page * perPage - 1,
        ]);
      }

      url = `${resource}?${stringify(query)}`;
      break;
    }
    case GET_ONE: {
      url = `${resource}/${params.id}`;
      break;
    }
    case CREATE: {
      url = resource;
      method = 'post';
      break;
    }
    case UPDATE: {
      url = `${resource}/${params.id}`;
      method = 'put';
      break;
    }
    case DELETE: {
      url = `${resource}/${params.id}`;
      method = 'delete';
      break;
    }
    case DELETE_MANY: {
      data = params.ids;
      url = resource;
      method = 'deleteMany';
      break;
    }
    case GET_MANY: {
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      };
      url = `${resource}?${stringify(query)}`;
      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }

  return api[method](url, data);
};
