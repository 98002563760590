import React from 'react';
import { node } from 'prop-types';
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

const InstituteForm = ({ toolbar }) => (
  <SimpleForm toolbar={toolbar} sanitizeEmptyValues={false}>
    <TextInput multiline source="name" />
    <TextInput source="phone" />

    <TextInput
      multiline
      source="address.address"
      label="Street name and address"
      parse={(value) => value}
    />
    <TextInput
      source="address.zipCode"
      label="Zip code"
      parse={(value) => value}
    />
    <TextInput source="address.city" label="City" parse={(value) => value} />
    <TextInput
      source="address.country"
      label="Country"
      parse={(value) => value}
    />
    <ReferenceInput
      source="adminId"
      reference="users"
      label="Admin"
      perPage={100}
      allowEmpty
      parse={(value) => value}
      sort={{ field: 'email', order: 'ASC' }}
    >
      <AutocompleteInput optionText="email" emptyValue={null} resettable />
    </ReferenceInput>
  </SimpleForm>
);

InstituteForm.propTypes = {
  toolbar: node.isRequired,
};

export default InstituteForm;
