const moduleName = 'SETTINGS';

export const LOAD_USER_START = `${moduleName}/LOAD_USER_START`;
export const LOAD_USER_SUCCESS = `${moduleName}/LOAD_USER_SUCCESS`;
export const LOAD_USER_FAIL = `${moduleName}/LOAD_USER_FAIL`;

export const UPDATE_PASSWORD_START = `${moduleName}/UPDATE_PASSWORD_START`;
export const UPDATE_PASSWORD_SUCCESS = `${moduleName}/UPDATE_PASSWORD_SUCCESS`;
export const UPDATE_PASSWORD_FAIL = `${moduleName}/UPDATE_PASSWORD_FAIL`;

export const UPDATE_FIELD = `${moduleName}/UPDATE_FIELD`;
